<template>
    <div>
        <Layout>
            <div class="row ml-2 mt-4 justify-content-between">
                <div class="col-lg-6">
                    <span>AWB or B/L (Bill of Lading)</span>
                    <br>
                    <span style="
                        font-size: 25px;
                        font-weight: 700;
                        color: black;
                    ">{{ listDataTimeline.timeline[0].bl_number }}</span>
                </div>
                <div class="col-lg-3">
                    <table>
                        <tr>
                            <td rowspan="2" v-if="listDataTimeline.timeline[0].date_receive !== ''"><i class="fa fa-check-circle text-primary mr-2" style="font-size: 20px;"></i></td>
                            <td>Date received : </td>
                        </tr>
                        <tr>
                            <td><b style="color: black;">{{ dateFormatingEta(listDataTimeline.timeline[0].date_receive) }} <span v-if="listDataTimeline.timeline[0].date_receive !== ''">WIB</span></b></td>
                        </tr>
                    </table>
                </div>
            </div>

            <div class="row mt-3 mb-3 text-center">
                <div class="col-12 table-responsive">
                    <!-- stepper -->
                    <section class="step-wizard">
                        <ul class="step-wizard-list">
                            <!-- <li class="step-wizard-item current-item">
                                <span class="progress-count">
                                    <i class="fa fa-calendar"></i>
                                </span>
                                <span class="progress-label">Proses Penjadwalan</span>
                            </li>
                            <li class="step-wizard-item">
                                <span class="progress-count">
                                    <i class="fa fa-box-open"></i>
                                </span>
                                <span class="progress-label">Proses Packing Barang</span>
                            </li>
                            <li class="step-wizard-item">
                                <span class="progress-count">
                                    <i class="fa fa-dolly"></i>
                                </span>
                                <span class="progress-label">Proses Pengiriman Port</span>
                            </li> -->
                            <li class="step-wizard-item" v-for="(item) in listDataTimeline.status_shipment" :key="item.id">
                                <span class="progress-count">
                                    <img :src="item.images" >
                                </span>
                                <span class="progress-label">{{ item.title }}</span>
                            </li>
                        </ul>
                    </section>
                    <!-- end stepper -->
                </div>
            </div>

            <div class="row"
                style="
                    background-color: #F8F8F8;
                    height: 64px;
                    padding: 20px 30px 20px 30px;">
                <div class="col-lg-10 col-sm-10">
                    <div style="
                        font-weight: 700;
                        font-size: 16px;
                    ">
                        <b>Tracking Info</b>
                    </div>
                </div>
                <div class="col-lg-2 col-sm-2" style="color: #F9A707">
                    <div style="
                        font-weight: 400;
                        font-size: 16px;
                        cursor: pointer;
                    " @click="refresh">
                    <img src="../../../../assets/images/icon-refresh.svg" alt=""> <b>Refresh</b>
                    </div>
                </div>
            </div>

            <div class="container" style="overflow-x : auto; overflow-y: hidden;">
                <!-- timeline -->
                <div v-for="(item, index) in listDataTimeline.timeline" :key="index" class="step completed mt-2" >
                    <div class="row">
                        <div class="col-1">
                            <b style="color: black;">{{ dateFormating(item.updated_at) }}</b>
                            <br>
                            <small>{{ timeFormating(item.updated_at) }}</small>
                        </div>
                        <div class="col-1 mt-2 ml-2 text-center">
                            <div class="v-stepper">

                                <div v-if="item.type === 2" class="circle-finished"></div>
                                <div v-else-if="item.type === 1" class="circle-next"></div>
                                <div v-else-if="item.type !== 2" class="circle"></div>
                                <div class="line"></div>
                            </div>
                        </div>
                        <div class="col-9">
                            <div class="row">
                                <div v-if="item.id !== 0 && item.port_desc !== ''" class="col-3">
                                    <b>{{ item.port_name }}</b>
                                    <br>
                                    <small>
                                        <img src="../../../../assets/images/port1.png" alt="" width="14px" height="14px">
                                        {{ item.port_desc }}, {{ item.port_country }}
                                    </small>
                                </div>
                                <div v-if="item.id === 0" class="col-3">
                                    <small>
                                        <img src="../../../../assets/images/scheduled-shipping-1.svg" class="mt-1" alt="" width="26px" height="26px">
                                        <!-- {{ item.port_desc }}, {{ item.port_country }} -->
                                    </small>
                                </div>
                                <div v-if="item.port_desc === ''" class="col-3 d-flex align-items-center">
                                    <b>{{ item.port_name }}</b>
                                    
                                </div>
                                <div v-if="item.description !== ''" class="col-9" style="
                                    background-color: #fff; 
                                    width: 900px;
                                    border-style: solid;
                                    border-color: #E0E0E0;
                                    border-width: 1px;
                                    border-radius: 3px;
                                    padding: 0.5rem 1rem;
                                    color: black;
                                ">{{ item.description }}</div>
                                <div v-else class="col-9" style="
                                    background-color: #fff; 
                                    width: 900px;
                                    border-style: solid;
                                    border-color: white;
                                    border-width: 1px;
                                    border-radius: 3px;
                                    padding: 0.5rem 1rem;
                                color: black;">
                                    
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- <div class="step completed mt-2">
                    <div class="row">
                        <div class="col-1">
                            <b style="color: black;">2022/08/19</b>
                            <br>
                            <small>11:30:00</small>
                        </div>
                        <div class="col-1 mt-2 ml-2">
                            <div class="v-stepper">
                                <div class="circle"></div>
                                <div class="line"></div>
                            </div>
                        </div>
                        <div class="col-9">
                            <div class="row">
                                <div class="col-3">
                                    <b>Delivery Port 1</b>
                                    <br>
                                    <small>
                                        <img src="../../../../assets/images/port1.png" alt="" width="14px" height="14px">
                                        Port 1, Myanmar
                                    </small>
                                </div>
                                <div class="col-9" style="
                                background-color: #fff; 
                                width: 900px;
                                border-style: solid;
                                border-color: #E0E0E0;
                                border-width: 1px;
                                border-radius: 3px;
                                padding: 0.5rem 1rem;
                                color: black;
                                ">Lorem ipsum, dolor sit amet consectetur adipisicing elit. Architecto, neque. Lorem ipsum dolor sit amet.</div>
                            </div>
                        </div>
                    </div>
                </div> -->
                <!-- end timeline -->

                <!-- <div class="step completed mt-2">
                    <div class="row">
                        <div class="col-2 mr-4">
                            <b style="color: black;">2022/08/19</b>
                            <br>
                            <small>11:30:00</small>
                        </div>
                        <div class="col-1 mt-2 ml-4">
                            <div class="v-stepper">
                                <div class="circle"></div>
                                <div class="line"></div>
                            </div>
                        </div>
                    </div>
                    <div class="content">
                        <div class="row">
                            <div class="col-3">
                                <b>Delivery Port 1</b> <br>
                                <small>
                                    <img src="../../../../assets/images/port1.png" alt="" width="14px" height="14px">
                                    Port 1, Myanmar
                                </small>
                            </div>
                            <div class="col-9 comment-timeline" style="
                                background-color: #fff; 
                                width: 900px;
                                border-style: solid;
                                border-color: #E0E0E0;
                                border-width: 1px;
                                border-radius: 3px;
                                padding: 0.5rem 1rem;
                                color: black;
                                ">
                                Lorem ipsum dolor, sit amet consectetur adipisicing elit. Voluptas corrupti reprehenderit a?
                            </div>
                        </div>
                    </div>
                </div> -->
            </div>

            <div class="row mt-4 pt-4">
                <div class="col-12">
                    <button class="btn" style="
                        background-color: #E0E0E0;
                    " @click.prevent="onBack">
                        <span style="color: #817F7F;">Cancel</span>
                    </button>
                </div>
            </div>
        </Layout>
    </div>
</template>

<script>
import Layout from '@layouts/main'
import { mapState, mapActions } from 'vuex'
// import { useRoute } from 'vue-router';
import moment from 'moment'

export default {
    components: {
        Layout
    },

    data(){
        return {
            dateFormating(date) {
                return date ? moment(date).format('DD/MMM/YYYY') : '-'
  			},
            dateFormatingEta(date) {
                return date ? moment(date).format('DD MMM YYYY HH:mm') : ''
  			},
            timeFormating(date) {
                return date ? moment(date).format('HH:mm') : ''
  			},
        }
    },
    
    computed: {
        ...mapState('statusShipment', ['listDataTimeline']),
    },

    mounted(){
        this.getTimeline(this.$route.params.id)
    },


    methods: {
        ...mapActions('statusShipment', ['getTimeline']),

        onBack(){
            this.$router.push({
                name: 'Tracking Shipment'
            })
        },
        refresh(){
            location.reload()
        }
    },
}
</script>

<style>
    /* .progress{
        background-color: #E3F3F0;
        width: 76px;
        height: 76px;
        border-radius: 50%;
    } */
    .step-wizard-list{
        list-style-type: none;
        border-radius: 10px;
        display: flex;
        padding: 20px 10px;
        position: relative;
        z-index: 10;
    }

    .step-wizard-item{
        padding: 0 20px;
        flex-basis: 0;
        -webkit-box-flex: 1;
        -ms-flex-positive: 1;
        flex-grow: 1;
        max-width: 100%;
        display: flex;
        flex-direction: column;
        text-align: center;
        min-width: 170px;
        position: relative;
    }

    .step-wizard-item + .step-wizard-item:after{
        content: '';
        position: absolute;
        left: 0;
        top: 35px;
        background: #E3F3F0;
        width: 100%;
        height: 5px;
        transform: translateX(-50%);
        z-index: -10;
    }

    .progress-count{
        height: 76px;
        width: 76px;
        display: flex;
        background: #E3F3F0;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        font-weight: 600;
        margin: 0 auto;
        color: #37AB96;
        font-size: 30px;
    }

    .progress-label{
        margin: 5% 0 0 0;
        color: #37AB96;
    }


    /* timeline vertical */
    
    .step {
        padding: 10px;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        background-color: cream;
    }
    .v-stepper {
        position: relative;
        /*   visibility: visible; */
    }

        /* regular step */
    .step .circle , .step .circle-finished, .step .circle-next {
        background-color: white;
        border: 3px solid gray;
        border-radius: 100%;
        width: 20px;    /* +6 for border */
        height: 20px;
        display: inline-block;
    }

    .step .line {
        top: 20px;
        left: 26px;
        /*   height: 120px; */
        height: 370%;
        position: absolute;
        border-left: 3px solid gray;
    }

    .step.completed .circle {
        visibility: visible;
        background-color: #37AB96;
        border-color: #DBE8E5;
    }
    .step.completed .circle-finished {
        visibility: visible;
        background-color: #F9A707;
        border-color: #DBE8E5;
    }
    .step.completed .circle-next {
        visibility: visible;
        background-color: #37AB96;
        border-color: #DBE8E5;
        margin-right: 35px;
    }

    .step.completed .line {
        border-left: 3px solid #DBE8E5;
    }

    .step.active .circle {
        visibility: visible;
        border-color: #37AB96;
        
    }
    .step.active .circle-next {
        visibility: visible;
        border-color: #37AB96;
        
    }
    .step.active .circle-finished {
        visibility: visible;
        border-color: #F9A707;
    }

    .step.empty .circle {
        visibility: hidden;
    }

    .step.empty .line {
        /*     visibility: hidden; */
        /*   height: 150%; */
        top: 0;
        height: 190%;
    }

    .step:last-child .line {
        border-left: 3px solid white;
        z-index: -1; 
        /* behind the circle to completely hide */
    }

    /* .content {
        margin-left: 20px;
        display: inline-block;
    } */
    

</style>